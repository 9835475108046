import './App.css';
import './styles/globals.css';

function App() {
  return (
    <div className="App">
    <div className="flex flex-col min-h-screen bg-black text-white">
          <header className="p-8 text-center bg-gradient-to-r from-purple-900 via-black to-indigo-900">
            <h1 className="text-5xl font-bold">RingBelle</h1>
            <p className="mt-4 text-2xl">ITのスペシャリストがビジネスを加速させます</p>
          </header>

          <main className="flex-grow p-8">
            <h2 className="text-3xl font-bold mb-6 text-center">会社の提供価値</h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-6 border-2 border-indigo-500 rounded-md text-center">
                <h3 className="text-2xl font-bold mb-4">DXコンサルティング</h3>
                <p>デジタルトランスフォーメーションに関する専門的なアドバイスを提供します。</p>
              </div>

              <div className="p-6 border-2 border-indigo-500 rounded-md text-center">
                <h3 className="text-2xl font-bold mb-4">プロダクト開発支援</h3>
                <p>製品開発の各フェーズでの支援を行います。</p>
              </div>

              <div className="p-6 border-2 border-indigo-500 rounded-md text-center">
                <h3 className="text-2xl font-bold mb-4">IT人材採用・教育支援</h3>
                <p>IT人材の採用と育成に関するサポートを提供します。</p>
              </div>
            </div>
            <div className="flex justify-center mt-10">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSf2HKnn15KyWHi-Q889TVaSpGAmLoA03R-aWXhfuelvzVrwrg/viewform" target="_blank" rel="noopener noreferrer" className="px-6 py-3 bg-indigo-500 text-white font-bold rounded-full hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-300 focus:ring-opacity-50">
                お問い合わせはこちら
              </a>
            </div>
          </main>

          <footer className="bg-gray-900 text-white text-center py-4">
            <p className="text-xl">©︎RingBelle</p>
          </footer>
        </div>
    </div>
  );
}

export default App;
